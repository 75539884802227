import React from "react";
import BookEdit from "../../components/admin/book-edit/book-edit";

const AdminBookEditPage = () => {
  return (
    <>
      <BookEdit />
    </>
  );
};

export default AdminBookEditPage;
