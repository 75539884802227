import React from "react";
import CategoryEdit from "../../components/admin/category-edit/category-edit";

const AdminCategoryEditPage = () => {
  return (
    <>
      <CategoryEdit />
    </>
  );
};

export default AdminCategoryEditPage;
