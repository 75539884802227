import React from "react";
import PublisherEdit from "../../components/admin/publisher-edit/publisher-edit";

const AdminPublisherEditPage = () => {
  return (
    <>
      <PublisherEdit />
    </>
  );
};

export default AdminPublisherEditPage;
