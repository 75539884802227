import React from "react";
import Users from "../../components/admin/users/users";

const AdminUsersPage = () => {
  return (
    <div>
      <Users />
    </div>
  );
};

export default AdminUsersPage;
