import React from "react";
import PublisherAdd from "../../components/admin/publisher-add/publisher-add";

const AdminPublisherAddPage = () => {
  return (
    <div>
      <PublisherAdd />
    </div>
  );
};

export default AdminPublisherAddPage;
