import React from "react";
import { Container } from "react-bootstrap";
import { settings } from "../../../utils/settings";

const ContactMap = () => {
  return (
    <Container fluid>
      <iframe
        title={`${settings}.siteName`}
        src={settings.mapEmbedUrl}
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullscreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </Container>
  );
};

export default ContactMap;
