export const settings = {
  apiURL: "https://library-app-backend-v2.herokuapp.com",
  siteName: "Library Fast",
  siteUrl: "https://libraryfast.com/",
  imageServer: "https://book-loan-center.herokuapp.com",
  email: "info@librarfast.com",
  phone1: "(912) 219-5959",
  phone2: "(912) 219-5959",
  address: "66 John St, New York, NY 10038, USA",
  description:
    "Library Fast provides loaning hundreds of books with only one click",
  mapEmbedUrl:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49552110.80679956!2d-145.726715!3d40.70875159999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a17f3010935%3A0xa2b6e7856a1adfaf!2sManhattan%20Business%20Center!5e0!3m2!1sen!2str!4v1672926765124!5m2!1sen!2str",
};
