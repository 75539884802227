import React from "react";
import CategoryAdd from "../../components/admin/category-add/category-add";

const AdminCategoryAddPage = () => {
  return (
    <div>
      <CategoryAdd />
    </div>
  );
};

export default AdminCategoryAddPage;
