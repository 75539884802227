import React from "react";
import Authors from "../../components/admin/authors/authors";

const AdminAuthorsPage = () => {
  return (
    <div>
      <Authors />
    </div>
  );
};

export default AdminAuthorsPage;
