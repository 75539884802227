import React from "react";
import AuthorEdit from "../../components/admin/author-edit/author-edit";

const AdminAuthorEditPage = () => {
  return (
    <>
      <AuthorEdit />
    </>
  );
};

export default AdminAuthorEditPage;
