import React from "react";
import Unauthorized from "../../components/general/unauthorized/unauthorized";

const UnauthorizedPage = () => {
  return (
    <>
      <Unauthorized />
    </>
  );
};

export default UnauthorizedPage;
