import React from "react";
import AdminReports from "../../components/admin/reports/admin-reports";

const AdminReportsPage = () => {
  return (
    <div>
      <AdminReports />
    </div>
  );
};

export default AdminReportsPage;
