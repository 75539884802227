import React from "react";
import Publishers from "../../components/admin/publishers/publishers";

const AdminPublishersPage = () => {
  return (
    <div>
      <Publishers />
    </div>
  );
};

export default AdminPublishersPage;
