import React from "react";
import Profile from "../../components/users/profile/profile";

const ProfilePage = () => {
  return (
    <>
      <Profile />
    </>
  );
};

export default ProfilePage;
