import React from "react";
import Books from "../../components/admin/books/books";

const AdminBooksPage = () => {
  return (
    <>
      <Books />
    </>
  );
};

export default AdminBooksPage;
